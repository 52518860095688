<style scoped>
  
</style>

<template>
    <ButtonTopbar buttonText="Créer une ligne préformatée" @onTopbarButtonClicked="displayCreateManualInvoiceLineModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute="manualInvoiceLine/list"
        :columnDefs="columnDefs" 
        :context="context"
        :frameworkComponents="frameworkComponents"
        aggridHeightSubstract="100px"
    />
        <Modal v-show="displayModalCreateOrEditManualInvoiceLine === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'pen']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditModeModalCreateOrEditManualInvoiceLineInputChange === 'create'">CRÉER UNE LIGNE PRÉFORMATÉE</div>
            <div v-show="createOrEditModeModalCreateOrEditManualInvoiceLineInputChange === 'edit'">MODIFIER UNE LIGNE PRÉFORMATÉE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="manualInvoiceLine.name"
                        label="Nom"
                        type="text"
                        name="name"
                        validator="text"
                        :error="formErrorsModalCreateOrEditManualInvoiceLine?.name?.error?.message"
                        @onChange="onFormModalCreateOrEditManualInvoiceLineInputChange"
                        :displayError="displayErrorModalCreateOrEditManualInvoiceLine"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="manualInvoiceLine.label"
                        label="Libellé"
                        type="textarea"
                        name="label"
                        validator="text"
                        :error="formErrorsModalCreateOrEditManualInvoiceLine?.label?.error?.message"
                        @onChange="onFormModalCreateOrEditManualInvoiceLineInputChange"
                        :displayError="displayErrorModalCreateOrEditManualInvoiceLine"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeCreateOrEditManualInvoiceLineModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitCreateOrEditManualInvoiceLineModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar';
    import BaseInput from '../../components/Base/BaseInput.vue';
    import BaseButton from '../../components/Base/BaseButton.vue';
    import Modal from '../../components/App/Modal.vue';
    import ListManualInvoiceLinesActionButtonsRenderer from './ListManualInvoiceLinesActionButtonsRenderer.vue';
    import axios from 'axios'

    export default {
        name: 'ManualInvoice',
        components: {
            Aggrid,
            ButtonTopbar,
            BaseInput,
            BaseButton,
            Modal,
            ListManualInvoiceLinesActionButtonsRenderer
        },
        data() {
            return {
                columnDefs: [
                    { field: 'name', headerName : `Nom`, flex: 1, lockPosition: true },
                    { field: 'label', headerName : `Libellé`, flex: 1, lockPosition: true },
                    { flex: 1, lockPosition: true, cellRenderer: 'ListManualInvoiceLinesActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListManualInvoiceLinesActionButtonsRenderer: ListManualInvoiceLinesActionButtonsRenderer
                },
                context: null,
                createOrEditModeModalCreateOrEditManualInvoiceLineInputChange: null,
                displayModalCreateOrEditManualInvoiceLine: false,
                manualInvoiceLine: null,
                displayErrorModalCreateOrEditManualInvoiceLine: false,
                formErrorsModalCreateOrEditManualInvoiceLine: {}
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetManualInvoiceLine();
        },
        methods: {
            displayCreateManualInvoiceLineModal() {
                this.createOrEditModeModalCreateOrEditManualInvoiceLineInputChange = 'create';
                this.resetManualInvoiceLine();
                this.displayModalCreateOrEditManualInvoiceLine = true;
            },
            displayEditManualInvoiceLineModal(manualInvoiceLine) {
                this.createOrEditModeModalCreateOrEditManualInvoiceLineInputChange = 'edit';

                axios
                .get(`manualInvoiceLine/${manualInvoiceLine.id}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((result) => {
                    this.manualInvoiceLine = result.data;
                    this.displayModalCreateOrEditManualInvoiceLine = true;
                })
                .catch(() => {});
            },
            resetManualInvoiceLine() {
                this.manualInvoiceLine = {
                    name: null,
                    label: null
                };
            },
            closeCreateOrEditManualInvoiceLineModal() {
                this.resetManualInvoiceLine();
                this.displayModalCreateOrEditManualInvoiceLine = false;
                this.displayErrorModalCreateOrEditManualInvoiceLine = false;
            },
            submitCreateOrEditManualInvoiceLineModal() {
                if (this.createOrEditModeModalCreateOrEditManualInvoiceLineInputChange === 'create') {
                    this.createManualInvoiceLine();
                } else if (this.createOrEditModeModalCreateOrEditManualInvoiceLineInputChange === 'edit') {
                    this.editManualInvoiceLine();
                }
            },
            onFormModalCreateOrEditManualInvoiceLineInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrorsModalCreateOrEditManualInvoiceLine[input.name] = input;
                } else {
                    delete this.formErrorsModalCreateOrEditManualInvoiceLine[input.name];
                }
            },
            createManualInvoiceLine() {
                if (!this.checkFormModalCreateOrEditManualInvoiceLine()) return;

                axios
                .post('manualInvoiceLine/create', this.manualInvoiceLine, { 
                    toastSuccessMessage: `Ligne préformatée créée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.emitter.emit('ag-grid-reload')
                    this.closeCreateOrEditManualInvoiceLineModal();
                })
                .catch(() => {});
            },
            editManualInvoiceLine() {
                if (!this.checkFormModalCreateOrEditManualInvoiceLine()) return;

                axios
                .put('manualInvoiceLine/update', this.manualInvoiceLine, { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.emitter.emit('ag-grid-reload')
                    this.closeCreateOrEditManualInvoiceLineModal();
                })
                .catch(() => {});
            },
            checkFormModalCreateOrEditManualInvoiceLine() {
                this.displayErrorModalCreateOrEditManualInvoiceLine = true;

                return Object.keys(this.formErrorsModalCreateOrEditManualInvoiceLine).length === 0;
            },
            deleteManualInvoiceLine(manualInvoiceId) {
                axios
                .put("manualInvoiceLine/delete/" + manualInvoiceId, null, {
                    toastSuccessMessage: `Suppression effectuée`,
                    toastError: true,
                    showPreloader: true,
                })
                .then(() => {
                    this.emitter.emit("ag-grid-reload");
                });
            }
        }
    }
</script>