<template>
    <div class="container-action-button">
        <BaseButton @click="onEditManualInvoiceLine" buttonText="" title="Modifier la ligne">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDeleteManualInvoiceLine" buttonText="" title="Supprimer la ligne">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListManualInvoiceLinesActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onEditManualInvoiceLine() {
                this.params.context.componentParent.displayEditManualInvoiceLineModal(this.params.data);
            },
            onDeleteManualInvoiceLine() {
                this.params.context.componentParent.deleteManualInvoiceLine(this.params.data.id);
            }
        }
    }        
</script>